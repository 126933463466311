<template>
  <div class="message-all noselect">
    <HeaderBar title="消息">
      <img
        src="@/assets/search/icon_qingchu@2x.png"
        alt=""
        slot="right"
        @click="clearMsg"
      />
    </HeaderBar>
    <div class="message-main m-scroll">
      <div class="msg-tools">
        <div class="tools-main">
          <div
            class="tool-item"
            @click="$router.push({ path: '/zh/logistics' })"
          >
            <img src="@/assets/message/icon_jiaoyi@2x.png" alt="" />
            <span>交易物流</span>
            <van-tag
              type="danger"
              v-if="readCode.logistics_message"
              class="tools-dot"
              round
              v-finger:press-move="pressMove"
              v-finger:multipoint-end="endMove"
              >{{ readCode.logistics_message }}</van-tag
            >
          </div>
          <div
            class="tool-item"
            @click="$router.push({ path: '/zh/activity' })"
          >
            <img src="@/assets/message/icon_huodong@2x.png" alt="" />
            <span>站内信</span>
            <van-tag
              type="danger"
              v-if="readCode.site_message"
              class="tools-dot"
              round
              v-finger:press-move="pressMove"
              v-finger:multipoint-end="endMove"
              >{{ readCode.site_message }}</van-tag
            >
          </div>
        </div>
      </div>
      <div class="msg-list">
        <van-swipe-cell>
          <div class="msg-item van-hairline--bottom m-padding">
            <img
              src="@/assets/leftbar/moobyyoho.png"
              alt=""
              @click="
                $router.push({ path: '/zh/customer' })
              "
            />
            <div class="msg-info">
              <p class="msg-store">
                <span
                  @click="
                    $router.push({ path: '/zh/customer' })
                  "
                  >Mooby官方客服</span
                >
              </p>
              <p
                class="msg-content"
                @click="
                  $router.push({ path: '/zh/customer' })
                "
              >
                点击查看客服联系方式~
              </p>
            </div>
          </div>
        </van-swipe-cell>
        <van-swipe-cell v-for="(item, index) in msgList" :key="index">
          <div class="msg-item van-hairline--bottom m-padding">
            <img
              src="@/assets/message/icon_jiaoyi@2x.png"
              alt=""
              @click="$router.push({ path: '/zh/chat', query: { id: item } })"
            />
            <div class="msg-info">
              <p class="msg-store">
                <span
                  @click="
                    $router.push({ path: '/zh/chat', query: { id: item } })
                  "
                  >Mooby官方</span
                >
                <span>2020.6.30</span>
              </p>
              <p
                class="msg-content"
                @click="$router.push({ path: '/zh/chat', query: { id: item } })"
              >
                有问题就点我～
              </p>
              <van-tag
                type="danger"
                class="msg-dot"
                round
                v-finger:press-move="pressMove"
                v-finger:multipoint-end="endMove"
                >1</van-tag
              >
            </div>
          </div>

          <template #right>
            <van-button
              square
              text="删除"
              type="danger"
              class="delete-button"
            />
          </template>
        </van-swipe-cell>
      </div>
    </div>
    <FooterBar />
  </div>
</template>

<script>
import HeaderBar from "@/components/zh/header-bar.vue";
import FooterBar from "@/components/zh/footer-bar.vue";
import { news_total, news_all_red } from "@/api/zh/message.js";
export default {
  components: { FooterBar, HeaderBar },
  data() {
    return {
      movePos: [0, 0],
      msgList: [],
      readCode: {},
    };
  },
  methods: {
    getData() {
      news_total().then((res) => {
        if (res) {
          this.readCode = res;
        }
      });
    },
    clearMsg() {
      // 清除消息
      if (this.readCode.logistics_message || this.readCode.site_message) {
        this.$dialog
          .confirm({
            title: "提醒",
            message: "确认清空消息记录吗？",
          })
          .then(() => {
            news_all_red().then((res) => {
              if (res) {
                this.$notify({ type: "success", message: res.msg });
              }
            });
          })
          .catch(() => {
            // on cancel
          });
      }else{
          this.$notify({ type: "warning", message: "暂无未读消息" });
      }
    },
    pressMove(e) {
      e.preventDefault();
      e.stopPropagation();
      this.movePos = [this.movePos[0] + e.deltaX, this.movePos[1] + e.deltaY];
      e.target.style.transform = `translate(${this.movePos[0]}px, ${this.movePos[1]}px)`;
      e.target.style.opacity =
        (20 -
          Math.sqrt(
            this.movePos[0] * this.movePos[0] +
              this.movePos[1] * this.movePos[1]
          )) /
        20;
    },
    endMove(e) {
      if (Math.abs(this.movePos[0]) > 20 || Math.abs(this.movePos[1]) > 20) {
        e.target.style.display = "none";
      } else {
        e.target.style.transform = "translate(0px, 0px)";
      }

      this.movePos = [0, 0];
      e.target.style.opacity = 1;
    },
  },
  created() {
    this.getData();
  },
  activated() {
    this.getData();
  },
};
</script>

<style scoped lang="less">
@import "./index.less";
</style>
<style lang="less">
.msg-list {
  .van-button {
    height: 100% !important;
  }
}
</style>