<template>
    <div class="header-bar">
        <img src="@/assets/search/bg@2x.png" alt="" class="header-bg">
        <div class="header-left">
            <slot name="left"></slot>
        </div>
        <div class="title">{{ title }}</div>
        <div class="header-right">
            <slot name="right"></slot>
        </div>
    </div>
</template>

<style scoped lang="less">
.header-bar{width:100%;height:56px;position: relative;z-index: 2;display: flex;
    .header-bg{width:100%;height:100%;position: absolute;top:0;left:0;z-index: -1}
    .header-left{width:56px;height:56px;}
    .title{
        flex: 1;
        text-align: center;line-height: 56px;font-size:18px;
        font-weight:400;
        color:rgba(255,255,255,1);
    }
    .header-right{width:56px;height:56px;
        img{width:30px;height:30px;margin:13px}
    }
}
</style>

<script>
export default {
    props: {
        title: {
            default: '',
            type: String
        }
    }
}
</script>